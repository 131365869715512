<template>
	<div :class="$style.wrapper">
		<DateFilter
			:class="$style.date"
			:date="filter.date"
			@change="filter.date = $event"
			:type="'Заказы'"
		/>
		<OrderSearch
			:class="$style.search"
			@search="filter.orderId = $event"
		/>
		<OrderSort
			:class="$style.sort"
			@sort="sort=$event"
		/>
		<span :class="$style.resolution">{{ this.screenResolution }}</span>
		<div :class="$style.orders">
			<div v-if="error">
				<div
					:class="$style.error"
					v-html="errorMsg"
				></div>
			</div>
			<div v-else-if="placeholder">placeholder</div>
			<template v-else-if="orders.length">
				<StatusConfirmPopup
					:class="$style.popup"
					:isWarnPopup="orderIsTooEarly"
					:time="orders[0].popupWarnLimit"
					v-if="showConfirmPopup"
					@close="showConfirmPopup = false"
					@toggleDoStep="setNewStatus"
				/>
				<OrderRow
					v-for="order in sortedOrders"
					:key="order.id"
					:order="order"
					@click.native="detailOrderId = order.id"
					:isCurrent="detailOrderId === order.id"
					@toggleFixed="toggleFixed($event)"
					@togglePrevStatus="showConfirmPopup = true; statusOrderId = $event"
					@toggleNextStatus="toggleStatus($event, true)"
					@directPrevStatus="showConfirmPopup = true; statusOrderId = $event.orderId; statusName=$event.statusName"
					@directNextStatus="statusName=$event.statusName; toggleStatus($event.orderId, true)"
					@toggleWarn="showConfirmPopup = true; orderIsTooEarly = true; statusOrderId = $event"
				/>
			</template>
			<div v-else>Заказов не найдено</div>
		</div>
		<OrderDetail
			:class="$style.detail"
			:orderId="detailOrderId"
		/>
	</div>
</template>

<script>
import debounce from "lodash/debounce";
import axios from 'axios'
import OrderRow from '@/components/block/Rest/OrderRow.vue'
import OrderDetail from '@/components/block/Rest/OrderDetail.vue'
import DateFilter from '@/components/block/Rest/DateFilter.vue'
import OrderSearch from '@/components/block/Rest/OrderSearch.vue'
import OrderSort from '@/components/block/Rest/OrderSort.vue'
import StatusConfirmPopup from '@/components/block/Rest/StatusConfirmPopup.vue';
import NavTabs from '@/components/block/NavTabs';
import qs from 'qs';
import {DateTime} from "luxon";
import {mapState} from "vuex";

//https://youtrack.ivsupport.ru/issue/VB-503
const priorities = {
	'P': [5, 5, 1, 2, 3, 4, 0],
	'I': [4, 4, 5, 1, 2, 3, 0],
	'C': [3, 3, 4, 5, 1, 2, 0],
	'K': [2, 2, 3, 4, 5, 1, 0],
	'F': [1, 1, 2, 3, 4, 5, 0]
};

export default {
	components: {OrderRow, OrderDetail, DateFilter, OrderSearch, OrderSort, StatusConfirmPopup, NavTabs},
	data()
	{
		return {
			loading: false,
			regularLoading: false,
			placeholder: true,
			error: false,
			errorMsg: 'Что-то пошло не так, перезагрузите страницу. Если не помогло, повторите авторизацию в личном кабинете',
			orders: [],
			detailOrderId: null,
			sort: 0,
			showConfirmPopup: false,
			statusBackOrderId: null,
			orderIsTooEarly: false,
			interval: null,
			filter: {
				date: DateTime.local(),
				order: null,
				orderId: null,
				status: '',
			},
			statusName: '',
			navItems: [
				{
					name: 'Все',
					target: ''
				},
				{
					name: 'Собираются',
					target: 'I'
				},
				{
					name: 'Курьер',
					target: 'K'
				},
				{
					name: 'Доставлен',
					target: 'F'
				},
			],
			screenResolution: `${screen.width}x${screen.height}`,
		}
	},
	computed: {
		...mapState(['currentOwnerRestaurant']),
		sortedOrders()
		{
			let index = this.sort;
			return this.orders.sort(function (d1, d2)
			{
				if (d1.fixed ^ d2.fixed)
				{
					if (d1.fixed) return -1;
					else if (d2.fixed) return 1;
				}
				let dif = priorities[d2.status][index] - priorities[d1.status][index]
				if (!d1.fixed && !d2.fixed && dif != 0) return dif;

				return d1.timeForSort - d2.timeForSort;
			});

		}
	},
	async mounted()
	{
		await this.loadOrders();
		this.placeholder = false;
	},
	methods: {
		async loadOrders()
		{
			if (!this.loading)
			{
				try
				{
					this.loading = true
					this.regularLoading = true
					let filter = qs.stringify({
						date: this.filter.date ? this.filter.date.toISODate() : undefined,
						orderId: this.filter.orderId,
						orderStatus: this.filter.status,
						currentOwnerRestaurant: this.currentOwnerRestaurant,
					})
					let resp = await axios.post('/lk/', filter);
					if (!resp.data.success) throw resp;
					this.orders = resp.data.orders
				} catch (e)
				{
					this.error = true
					if (e.response && e.response.data.getOrdersError)
					{
						if (e.response.data.unauthorized)
						{
							clearInterval(this.interval);
							this.$store.commit('setUser', '');
						} else if (this.$store.state.user.LK_ACCESS && this.$store.state.user.NO_REST)
							this.errorMsg = "Вы имеете доступ в ЛК кухни, но не являетесь работником ни одного ресторана";
						else
							throw new Error(e.response.data.error);
					} else
						throw e
				} finally
				{
					this.loading = false
					this.regularLoading = false
				}
			}
		},
		async toggleFixed(order)
		{
			try
			{
				this.loading = true
				let filter = qs.stringify({
					currentOwnerRestaurant: this.currentOwnerRestaurant,
					orderId: order.id
				});
				let resp = await axios.post('/lk/toggleFixed/', filter);
				if (!resp.data.success) throw resp;
			} catch (e)
			{
				this.error = true;
				throw e
			} finally
			{
				this.orders[this.orders.indexOf(order)].fixed = !order.fixed;
				this.loading = false
			}
		},
		async setNewStatus()
		{
			this.showConfirmPopup = false;
			if (this.orderIsTooEarly)
			{
				this.orderIsTooEarly = false;
				this.toggleStatus(this.statusOrderId, true);
			} else this.toggleStatus(this.statusOrderId, false);
		},
		async toggleStatus(orderId, type)
		{
			try
			{
				this.loading = true
				let filter = qs.stringify({
					date: this.filter.date ? this.filter.date.toISODate() : undefined,
					currentOwnerRestaurant: this.currentOwnerRestaurant,
					orderId: this.filter.orderId,
					orderIdForStatus: orderId,
					toggleNext: type,
					pickup: this.orders.find(order => order.id === orderId)?.pickup,
					statusName: this.statusName
				});
				let resp = await axios.post('/lk/toggleStatus/', filter);
				if (!resp.data.success) throw resp;
				this.orders = resp.data.orders
			} catch (e)
			{
				this.error = true;
				throw e
			} finally
			{
				this.loading = false
			}
		}
	},
	watch: {
		filter: {
			handler: debounce(function ()
			{
				this.loadOrders()
			}, 500),
			deep: true
		},
		orders: {
			handler()
			{
				this.$emit('changeOrdersCount', this.orders.length);
			},
			deep: true
		}
	},
	beforeMount()
	{
		this.interval = setInterval(this.loadOrders, 1000 * 10);
	},
	beforeDestroy()
	{
		clearInterval(this.interval);
	}
}
</script>

<style
	lang="scss"
	module
>
.wrapper
{
	display: grid;
	grid:
            "date date detail" 60px
            "search sort detail" 60px
            "orders orders  detail" 1fr
            / 2fr 1fr minmax(250px, 30%);
	padding: 12px;
	gap: 12px;
	font-family: 'Roboto', 'PT Sans', sans-serif;
	overflow: hidden;

	.date
	{
		grid-area: date;
	}

	.tabs
	{
		grid-area: tabs;
		height: 36px;
	}

	.search
	{
		grid-area: search;
	}

	.sort
	{
		grid-area: sort;
	}

	.orders
	{
		display: grid;
		gap: 8px;
		align-content: start;
		grid-area: orders;
		overflow: auto;

		&::-webkit-scrollbar
		{
			width: 5px;

			&-thumb
			{
				background-color: darkgrey;
				border-radius: 3px;
			}
		}

	}

	.detail
	{
		grid-area: detail;
		background-color: #F8F8F8;
		border-radius: 16px;
		overflow: auto;

		&::-webkit-scrollbar
		{
			width: 5px;

			&-thumb
			{
				background-color: darkgrey;
				border-radius: 3px;
			}
		}
	}

	.popup
	{
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		display: table;
		transition: opacity 0.3s ease;
		display: grid;
		align-items: center;
	}
}

.loading
{
	background: white;
	width: 100%;
	height: 100%;
	opacity: 0.6;
	z-index: 1;
}

.error
{
	margin: 20px;
	font-size: 20px;
	color: #FD470E;
}

.resolution
{
	position: absolute;
	top: 136px;
	font-size: 8px;
}
</style>
